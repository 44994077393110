import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { ErrorText, Input, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';

import { DoctorsSelect, useSetDoctors } from '@/entities/doctors';
import { ModalID, modalModel } from '@/entities/modal';

import {
  ADD_DOCTORS_FORM_ID,
  AddDoctorsPayload,
  addDoctorsSchema,
  i18n,
} from '../../config';
import * as treatingDoctorsModel from '../../model';
import { TreatingDoctorOption } from '../TreatingDoctorOption/TreatingDoctorOption';
import drawerStyles from '../TreatingDoctorsDrawer/TreatingDoctorsDrawer.module.scss';

import styles from './AddDoctorsModal.module.scss';

export const AddDoctorsModal = () => {
  const [globalFormError, setGlobalFormError] = useState<string>();
  const [searchValue, setSearchValue] = useState('');

  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();
  const addDoctors = useSetDoctors();
  const doctorsOptions = useAppSelector(
    treatingDoctorsModel.selectors.selectOrganizationDoctorsOptions,
  );
  const {
    visible,
    data: { patientID = '', currentDoctorIDs },
  } = useAppSelector(modalModel.selectors.selectTreatingDoctorsModal);

  const closeModal = () => {
    dispatch(modalModel.actions.closeModal(ModalID.TreatingDoctorsModal));
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<AddDoctorsPayload>({
    resolver: yupResolver(addDoctorsSchema),
  });

  const onSubmit: SubmitHandler<AddDoctorsPayload> = async (data) => {
    const { treatingDoctors } = data;

    try {
      setGlobalFormError(undefined);

      await addDoctors({ patientID, doctorIDs: treatingDoctors as string[] });

      closeModal();
    } catch (error) {
      setGlobalFormError((error as Error).message);
    }
  };

  const doctorsFilteredOptions = doctorsOptions.filter((doctor) =>
    doctor.label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const okButtonText = isMobile
    ? formatMessage({ id: 'global.save', defaultMessage: 'Save' })
    : formatMessage(i18n.applyButtonText);

  const title = isMobile
    ? formatMessage(i18n.mobileTitle)
    : formatMessage(i18n.title);

  // Set default
  useEffect(() => {
    reset({ treatingDoctors: currentDoctorIDs });
  }, [patientID, currentDoctorIDs]);

  return (
    <Modal
      containerClassName={cn(styles.container)}
      headerClassName={cn(isMobile && drawerStyles.header)}
      bodyClassName={cn(isMobile && drawerStyles.body)}
      shouldCloseOnOverlayClick={isMobile}
      shouldRenderCancelButton={!isMobile}
      title={title}
      isOpen={visible}
      onCancel={closeModal}
      okButtonText={okButtonText}
      okButtonProps={{
        type: 'submit',
        form: ADD_DOCTORS_FORM_ID,
        disabled: !isDirty,
        loading: isSubmitting,
      }}
    >
      {isMobile && (
        <Input
          name="filterDoctor"
          type="search"
          placeholder={formatMessage(i18n.searchPlaceholder)}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
      <form
        id={ADD_DOCTORS_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <Controller
          control={control}
          name="treatingDoctors"
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            isMobile ? (
              <div className={drawerStyles.doctorsList}>
                {doctorsFilteredOptions.map((doctor) => (
                  <TreatingDoctorOption
                    key={doctor.value}
                    label={doctor.label}
                    avatarSrc={doctor.avatarSrc}
                    value={doctor.value}
                    isSelected={value.includes(doctor.value)}
                    onClick={(doctorID) => {
                      if (value.includes(doctorID)) {
                        onChange(value.filter((id) => id !== doctorID));
                        return;
                      }
                      onChange([...value, doctorID]);
                    }}
                  />
                ))}
              </div>
            ) : (
              <DoctorsSelect
                options={doctorsOptions}
                value={value as string[]}
                onChange={(event) => onChange(event)}
                error={error?.message}
              />
            )
          }
        />

        <ErrorText className={styles.formGlobalError} error={globalFormError} />
      </form>
    </Modal>
  );
};
